<!--  -->
<template>
  <div class="div-main">
    <div class="box">

      <p class="box-title">配置模型知识库</p>
      <div class="box-nav">
        <div :class="{ 'active': selectIndex == 0 }" @click="selectItem(0)">上传文档资料</div>
        <div :class="{ 'active': selectIndex == 1 }" @click="selectItem(1)">prompt</div>

      </div>

      <keep-alive>
        <router-view ref="child" @update="updateSelect" @updateData="updateData"></router-view>
      </keep-alive>


    </div>

    <img src="https://cdn.shhd.info/APP/sys_img/digitalEmployee/jumbdialog.png" class="jumbimg" @click="jumbDialog">

  </div>
</template>

<script>
import common from "common/js/common";
export default {
  data () {
    return {
      selectIndex: 0,
      empId: null,
      oid: null,
    };
  },
  methods: {
    selectItem (index) {


      if (index == 0) {

        this.$refs.child.remindPrompt()
        // this.$router.replace({ path: '/pmt/fileup?eid=' + this.$Base64.encode(this.empId + "") })
      }
      else if (index == 1) {

        sessionStorage.setItem('current_name', index)
        this.selectIndex = index;
        if (sessionStorage.getItem('empId') == null) {
        } else {
          this.empId = sessionStorage.getItem('empId');
        }
        if (common.isStringEmpty(sessionStorage.getItem('flag'))) {
          this.$router.replace({ path: '/pmt/ept?eid=' + this.$Base64.encode(this.empId + "") })
        } else {
          this.$router.replace({ path: '/pmt/ept?eid=' + this.$Base64.encode(this.empId + "") + "&flag=1" })
        }




        //this.$refs.child.remindPrompt()

      }
    }
    ,
    updateSelect () {


      this.selectIndex = 0;
      sessionStorage.setItem('current_name', this.selectIndex)
      if (sessionStorage.getItem('empId') == null) {
      } else {
        this.empId = sessionStorage.getItem('empId');
      }
      this.$router.replace({ path: '/pmt/fileup?eid=' + this.$Base64.encode(this.empId + "") })

    },
    updateData (empId1, oid1) {
      this.empId = empId1;
      this.oid = oid1;
    },
    jumbDialog () {

      if (sessionStorage.getItem('empId') == null) {
      } else {
        this.empId = sessionStorage.getItem('empId');
      }
      console.log(this.$common.outAddress() + "---" + this.empId);
      window.open(this.$common.outAddress() + 'conversation?' +
        this.$Base64.encodeURI('id=' + JSON.stringify([parseInt(this.empId + "")])));
    }
  },
  //生命周期 - 创建完成（访问当前this实例）
  created () {



    if (sessionStorage.getItem('current_name') == null) {

      this.selectIndex = 0;
    } else {
      this.selectIndex = sessionStorage.getItem('current_name')
    }
  },
  //生命周期 - 挂载完成（访问DOM元素）
  mounted () {
  },



}
</script>
<style scoped>
/* @import url(); 引入css类 */
.jumbimg {
  width: 192px;
  height: 60px;
  position: fixed;
  right: 40px;
  bottom: 60px;
}

.div-main {
  padding-top: 20px;
  padding-bottom: 20px;
}

.box {

  display: flex;
  flex-direction: column;
  
  width: 1200px;
  margin: auto;

  padding-left: 60px;
  padding-right: 60px;

  background: #FFFFFF;
  box-shadow: 0px 4px 12px 0px rgba(9, 56, 128, 0.05);
  border-radius: 12px 12px 12px 12px;
}

.box-title {
  font-size: 30px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 600;
  color: #000000;
  padding-top: 40px;
  line-height: 34px;
}

.box-nav {
  display: flex;
  margin-top: 6px;
  margin-bottom: 20px;
  border-bottom: 1px solid #ECF1F8;
}

.box-nav div {
  height: 56px;
  position: relative;
  line-height: 56px;
  text-align: center;
  color: rgba(0, 0, 0, 0.4);
  margin-right: 40px;
  font-size: 18px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  cursor: pointer;
}

.box-nav .active {
  position: relative;
  /* display: block !important; */
  color: #000000 !important;
  font-weight: 700;
}

.box-nav .active:after {
  position: absolute;
  left: 0;
  bottom: 0;
  content: '';
  width: 100%;
  height: 2px;
  background: #226CFF;
}
</style>